import React  from "react"
import AnimateTexts from "../../components/animations/animate-texts"
import CustomContainer from "../../components/general/contianer"
import AnimateProductCard from "../../components/animations/animate-product-card"
import Footer from "../../components/footer/footer";
import SEO from "../../components/seo/seo"
import { graphql, useStaticQuery } from "gatsby"
import { Ingredients } from "../../models/product-item"
import { imageKitLink } from "../../utils/fomatters"

const OurIngredients = ({transitionStatus}) => {

    const ingredientsQuery = useStaticQuery(graphql`{
            ingredients: allIngredients
             {
                edges {
                    node {
                        title
                        description
                        short_description
                        image
                        products
                    }
                }
              }
        }`)

    const ingredients = ingredientsQuery['ingredients'].edges.map((el) => new Ingredients(el.node));

    return (
        <>
            <SEO description={"Get to know our product ingredients that help yield results for you!"} title={"Our Ingredients"} pathname={"/our-ingredients"}/>
            <section className={"ingredients_section bg-background-dark py-12 sm:py-32"} style={{ marginTop: "112px" }}>
                <div className={"flex justify-center"}>
                    <AnimateTexts className={`ingredients_header`} transitionStatus={transitionStatus} delay={400}>
                        <h1 className={"font-bold font-display text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4 max-w-2xl"} data-text-animate={true}>
                            Explore our unique, natural and clean ingredients.
                        </h1>
                    </AnimateTexts>
                </div>
            </section>
            <section className={"ingredients_cards_sections min-h-screen"}>
                <CustomContainer>
                    <div className={"my-6 mt-12"}>
                        <AnimateProductCard className={`collection_ingredients`} transitionStatus={transitionStatus} delay={150}>
                            <ul className={"grid grid-cols-2 gap-3 md:grid-cols-3 md:gap-6 xl:grid-cols-4 xl:gap-8"}>
                                {
                                    ingredients.map((ingredient, index) => {
                                        return <li key={`ingredient_${index}`} data-card-animate={true}>
                                            <div className={"relative w-full h-full flex flex-col rounded-2xl overflow-hidden border border-gray-200 "}>
                                                <div className={"relative w-full aspect-h-5 aspect-w-5"}>
                                                    {/*Ingredient Image*/}
                                                    <div className={"absolute w-auto h-auto"} style={{ background: `#e7e8e3`, }}>
                                                        <img className={"w-full h-full absolute left-0 top-0 object-cover no-repeat"} src={imageKitLink(ingredient.image)} alt={ingredient.title} loading={"lazy"}/>
                                                    </div>
                                                </div>
                                                <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 lg:px-6 lg:pb-6"}>
                                                    {/*Product Title*/}
                                                    <p className={"font-display font-medium text-xl lg:text-2xl"}>
                                                        {ingredient.title}
                                                    </p>
                                                    <p className={"font-sans text-sm mt-1 mr-2 opacity-60"}>
                                                        {ingredient.short_description}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </AnimateProductCard>
                    </div>
                </CustomContainer>
            </section>
            <div className={"mb-4"}/>
            <Footer transitionStatus={transitionStatus}/>
        </>

    )

}

export default OurIngredients;